export default {
  selectTcierreParte (Vue) {
    const tables = Vue.$offline.db.tables
    const tcierreParte = Vue.$offline.tcierreParte
    return Vue.$offline.db
      .select()
      .from(tables.tcierre_parte)
      .innerJoin(tcierreParte.dummyTable, tcierreParte.pk.eq(tcierreParte.dummyPk))
      .where(tables.tcierre_parte.estado.gt(0))
      .orderBy(tables.tcierre_parte.descripcion)
      .exec()
  },
  async selectCantAccionesPendientes (Vue, idparteTrabajo) {
    return (await Vue.$offline.parteTrabajo.selectCantAccionesPendientes(idparteTrabajo))[0].cant_acciones_pendientes
  },
  async selectFormData (Vue, id) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .leftOuterJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .innerJoin(
        tables.tactuacion_tsistema,
        tables.orden_trabajo.idtactuacion_tsistema.eq(tables.tactuacion_tsistema.idtactuacion_tsistema)
      )
      .innerJoin(
        tables.tactuacion,
        tables.tactuacion_tsistema.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(id),
          Vue.$offline.db.op.or(
            tables.checklist_ot.estado.gt(0),
            tables.checklist_ot.idchecklist_ot.isNull(),
          )
        )
      )
      .exec()
    )[0]
  },
}
