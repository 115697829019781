<template>
  <b10-base>
    <v-row
      class="justify-center"
    >
      <v-switch
        v-if="finalizarParteVisible && !soloUnFinalizar"
        v-model="form.finalizarParte"
        label="Parte"
        :rules="finalizarRule()"
      />
      <v-switch
        v-if="finalizarChecklistVisible && !soloUnFinalizar"
        v-model="form.finalizarChecklist"
        :rules="finalizarRule()"
        class="ml-4"
        label="Checklist"
      />
    </v-row>
    <template
      v-if="form.finalizarParte"
    >
      <b10-textarea
        v-model="form.comentario_final_tecnico"
        label="Comentario final del técnico"
      />
      <b10-textarea
        v-model="form.comentario_final_presupuesto"
        label="Comentario para presupuesto"
      />
      <b10-textarea
        v-model="form.notas_internas"
        label="Notas internas"
      />
      <b10-autocomplete
        v-if="tcierresParte.length > 0"
        v-model="form.idtcierre_parte"
        :items="tcierresParte"
        item-value="tcierre_parte.idtcierre_parte"
        item-text="tcierre_parte.descripcion"
        label="Tipo de cierre"
        clearable
        :rules="formRules.idtcierre_parte"
      />
      <v-radio-group
        v-if="cantAccionesPendientes > 0"
        v-model="form.dejar_acciones_pendientes"
      >
        <v-radio
          label="Dejar las acciones pendientes para el próximo parte"
          :value="true"
        />
        <v-radio
          label="Las acciones pendientes no se van a realizar"
          :value="false"
        />
      </v-radio-group>
      <v-checkbox
        v-model="form.copiar_comentario_aalbaran"
        label="Copiar comentario como observaciones de albarán"
      />
    </template>
    <template
      v-if="form.finalizarChecklist"
    >
      <b10-textarea
        v-model="form.observaciones_checklist"
        label="Observaciones del checklist"
      />
    </template>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteFinalizarFormData'
import { PARTE_TRABAJO, CHECKLIST, PARAMETRO } from '@/utils/consts'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        finalizarParte: false,
        finalizarChecklist: false,
        comentario_final_tecnico: null,
        comentario_final_presupuesto: null,
        notas_internas: null,
        idtcierre_parte: null,
        dejar_acciones_pendientes: true,
        copiar_comentario_aalbaran: false,
        observaciones_checklist: null,
      },
      formRules: {
        idtcierre_parte: [
          v => (!this.tcierreParteObligado) || (!!v && this.tcierreParteObligado) || 'Campo requerido'
        ],
      },
      tcierreParteObligado: false,
      finalizarParteVisible: false,
      finalizarChecklistVisible: false,
      cantAccionesPendientes: 0,
    }
  },
  computed: {
    soloUnFinalizar () {
      let cantFinalizar = 0
      if (this.finalizarParteVisible) {
        cantFinalizar++
      }
      if (this.finalizarChecklistVisible) {
        cantFinalizar++
      }
      return cantFinalizar === 1
    },
  },
  async created () {
    this.tcierresParte = await Data.selectTcierreParte(this)
    this.$set(
      this.form,
      'copiar_comentario_aalbaran',
      (await this.$offline.parametro.valorParametro(PARAMETRO.parametros.COPIAR_COMENTARIOS_A_ALBARAN)) === '1'
    )
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'comentario_final_tecnico', formData.parte_trabajo.comentario_final_tecnico)
      this.$set(this.form, 'comentario_final_presupuesto', formData.parte_trabajo.comentario_final_presupuesto)
      this.$set(this.form, 'notas_internas', formData.orden_trabajo.notas_internas)
      this.$set(this.form, 'idtcierre_parte', formData.parte_trabajo.idtcierre_parte)
      this.$set(this.form, 'copiar_comentario_aalbaran', formData.parte_trabajo.copiar_comentario_aalbaran)
      this.$set(this.form, 'observaciones_checklist', formData.checklist_ot.observaciones)
      this.cantAccionesPendientes = await Data.selectCantAccionesPendientes(this, id)
      // tipo de cierre
      this.tcierreParteObligado = formData.tactuacion.obliga_tcierre_parte
      // opciones visibles para finalizar
      this.finalizarParteVisible = formData.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente
      this.finalizarChecklistVisible = (
        formData.checklist_ot.idchecklist_ot && formData.checklist_ot.estado === CHECKLIST.estados.pendiente
      )
      this.$set(this.form, 'finalizarParte', this.finalizarParteVisible)
      this.$set(this.form, 'finalizarChecklist', this.finalizarChecklistVisible)
    },
    finalizarRule () {
      // algo debe finalizar
      if (!this.form.finalizarParte && !this.form.finalizarChecklist) {
        return [false]
      }
    },
  }
}
</script>
